<template>
  <div class="auth-bg">
    <div class="inner-content">
      <div class="img-wrapper">
        <img src="../../assets/logo.png" alt="" />
      </div>
      <p>
        WIF helps you communicate with your teammates, track events, keep
        schedules, team organization and much more.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Left",
};
</script>

<style></style>
