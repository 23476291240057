<template>
  <div class="top-logo">
    <div class="logo-wrapper">
      <img src="../../assets/logo.png" alt="logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthTop",
};
</script>

<style></style>
